<template>
  <div
    class="modal-menu"
    :class="[{ active: menuItems.active }, { mobileCatalog }]"
    @click.stop="closeModal"
  >
    <div class="modal-menu__wrapper" @click.stop>
      <section class="modal-menu__content">
        <header class="modal-menu__header">
          <div class="modal-menu__header-block">
            <Logo :orange="true" @click.stop="closeModal" />
            <ButtonLanguage :modal-menu="true" />
            <button class="modal-menu__close" @click="closeModal">
              <img src="~~/assets/icons/cross-white.svg" alt="close button" />
            </button>
          </div>
          <div class="modal-menu__header-block">
            <div
              v-if="null === userStore.currentUser"
              class="modal-menu__account"
            >
              <img
                src="~~/assets/icons/account-header.svg"
                alt="account"
                class="modal-menu__account-img"
              />
              <div class="modal-menu__account-buttons">
                <div class="modal-menu__account-btn" @click="signIn">
                  {{ _T("@Log in") }}
                </div>
                <div class="modal-menu__account-buttons-line"></div>
                <div class="modal-menu__account-btn" @click="signUp">
                  {{ _T("@Registration") }}
                </div>
              </div>
            </div>
            <MyLink
              v-else
              name="account_profile"
              class="modal-menu__account"
              @click.stop="closeModal"
            >
              <div class="modal-menu__account-img-w">
                <img
                  src="~~/assets/icons/account-logo.svg"
                  alt="account logo"
                />
              </div>
              <p class="modal-menu__account-title">
                {{ userStore.userFullName }}
              </p>
            </MyLink>
          </div>
        </header>
        <main class="modal-menu__main">
          <div class="modal-menu__main-wrapper">
            <section class="modal-menu__section">
              <ModalMenuItem
                class="modal-menu__item mobileCatalog"
                :is-button="true"
                @click="openCatalog"
              >
                <template #title>
                  <span class="modal-menu__item-title">{{
                    _T("@Goods catalog")
                  }}</span>
                </template>
                <template #image>
                  <img
                    src="~~/assets/icons/catalog-black.svg"
                    :alt="_T('@Goods catalog')"
                  />
                </template>
              </ModalMenuItem>
            </section>

            <section class="modal-menu__section">
              <ModalMenuItem
                class="modal-menu__item"
                :is-button="true"
                @click="showCart"
              >
                <template #title>
                  <span class="modal-menu__item-title"
                    >{{ `${_T("@Cart")}` }}
                  </span>
                </template>
                <template #image>
                  <div class="cart-img">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M19.5 22C20.3284 22 21 21.3284 21 20.5C21 19.6716 20.3284 19 19.5 19C18.6716 19 18 19.6716 18 20.5C18 21.3284 18.6716 22 19.5 22Z"
                        fill="#2B2B2B"
                        stroke="#2B2B2B"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M9.5 22C10.3284 22 11 21.3284 11 20.5C11 19.6716 10.3284 19 9.5 19C8.67157 19 8 19.6716 8 20.5C8 21.3284 8.67157 22 9.5 22Z"
                        fill="#2B2B2B"
                        stroke="#2B2B2B"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M5 4H22L20 15H7L5 4ZM5 4C4.83333 3.33333 4 2 2 2"
                        stroke="#2B2B2B"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M20 15H7H5.23077C3.44646 15 2.5 15.7812 2.5 17C2.5 18.2188 3.44646 19 5.23077 19H19.5"
                        stroke="#2B2B2B"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    <div v-if="cartQuantity > 0" class="cart-number">
                      <span class="cart-count">{{ cartQuantity }}</span>
                    </div>
                  </div>
                </template>
              </ModalMenuItem>

              <ModalMenuItem
                :route-name="'contacts'"
                class="modal-menu__item"
                @click="closeModal()"
              >
                <template #title>
                  <span class="modal-menu__item-title">
                    {{ _T("@Contacts") }}
                  </span>
                </template>
                <template #image>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M22 6.6C22 9.69279 16.5 15 16.5 15C16.5 15 11 9.69279 11 6.6C11 3.50721 13.4624 1 16.5 1C19.5376 1 22 3.50721 22 6.6Z"
                      stroke="#2B2B2B"
                      stroke-width="1.5"
                    />
                    <path
                      d="M16.5 7C16.7761 7 17 6.77614 17 6.5C17 6.22386 16.7761 6 16.5 6C16.2239 6 16 6.22386 16 6.5C16 6.77614 16.2239 7 16.5 7Z"
                      fill="#2B2B2B"
                      stroke="#2B2B2B"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M14.0881 17.5265L10.9988 18.125C8.91207 17.0779 7.6231 15.875 6.87294 14L7.45053 10.9024L6.35873 8L4.05955 8C2.932 8 2.04472 8.93144 2.24287 10.0414C2.62713 12.1941 3.61211 15.6148 6.12279 18.125C8.76171 20.7634 12.4747 22.0211 14.8083 22.5849C15.9669 22.8649 17 21.9575 17 20.7655L17 18.6359L14.0881 17.5265Z"
                      fill="white"
                      stroke="#2B2B2B"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </template>
              </ModalMenuItem>
            </section>

            <section class="modal-menu__section">
              <ModalMenuItem
                :href="`tel:${_T('@Call center number')}`"
                class="modal-menu__item"
                @click="closeModal()"
              >
                <template #title>
                  <span class="modal-menu__item-title">
                    {{ _T("@Sales department") }}:
                  </span>
                  <span class="modal-menu__item-title">
                    {{ _T("@Call center number") }}
                  </span>
                </template>
                <template #image>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M18.1181 14.702L13.9998 15.5C11.2181 14.1038 9.49985 12.5 8.49985 10L9.2698 5.8699L7.81436 2L4.06344 2C2.9359 2 2.04799 2.93178 2.21639 4.04668C2.63679 6.83 3.87638 11.8765 7.49985 15.5C11.305 19.3052 16.7856 20.9564 19.8019 21.6127C20.9666 21.8662 21.9998 20.9575 21.9998 19.7655L21.9998 16.1812L18.1181 14.702Z"
                      stroke="rgb(43 43 43)"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </template>
              </ModalMenuItem>

              <ModalMenuItem
                :href="`tel:${_T('@Technical Specialist tel.num')}`"
                class="modal-menu__item"
                @click="closeModal"
              >
                <template #title>
                  <span class="modal-menu__item-title">
                    {{ _T("@Technical support") }}:
                  </span>
                  <span class="modal-menu__item-title">
                    {{ _T("@Technical Specialist tel.num") }}
                  </span>
                </template>
                <template #image>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M20 11C20 6.58172 16.4183 3 12 3C7.58172 3 4 6.58172 4 11"
                      stroke="#2B2B2B"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M2 15.4384V13.5616C2 12.6438 2.62459 11.8439 3.51493 11.6213L5.25448 11.1864C5.63317 11.0917 6 11.3781 6 11.7685V17.2315C6 17.6219 5.63317 17.9083 5.25448 17.8136L3.51493 17.3787C2.62459 17.1561 2 16.3562 2 15.4384Z"
                      stroke="#2B2B2B"
                      stroke-width="1.5"
                    />
                    <path
                      d="M22 15.4384V13.5616C22 12.6438 21.3754 11.8439 20.4851 11.6213L18.7455 11.1864C18.3668 11.0917 18 11.3781 18 11.7685V17.2315C18 17.6219 18.3668 17.9083 18.7455 17.8136L20.4851 17.3787C21.3754 17.1561 22 16.3562 22 15.4384Z"
                      stroke="#2B2B2B"
                      stroke-width="1.5"
                    />
                    <path
                      d="M20 18V18.5C20 19.6046 19.1046 20.5 18 20.5H14.5"
                      stroke="#2B2B2B"
                      stroke-width="1.5"
                    />
                    <path
                      d="M13.5 22H10.5C9.67157 22 9 21.3284 9 20.5C9 19.6716 9.67157 19 10.5 19H13.5C14.3284 19 15 19.6716 15 20.5C15 21.3284 14.3284 22 13.5 22Z"
                      stroke="#2B2B2B"
                      stroke-width="1.5"
                    />
                  </svg>
                </template>
              </ModalMenuItem>

              <ModalMenuItem
                :href="`tel:${_T('@Technical Specialist 2 tel.num')}`"
                class="modal-menu__item"
                @click="closeModal"
              >
                <template #title>
                  <span class="modal-menu__item-title">
                    {{ _T("@Technical Specialist 2 tel.num") }}
                  </span>
                </template>
                <template #image>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M20 11C20 6.58172 16.4183 3 12 3C7.58172 3 4 6.58172 4 11"
                      stroke="#2B2B2B"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M2 15.4384V13.5616C2 12.6438 2.62459 11.8439 3.51493 11.6213L5.25448 11.1864C5.63317 11.0917 6 11.3781 6 11.7685V17.2315C6 17.6219 5.63317 17.9083 5.25448 17.8136L3.51493 17.3787C2.62459 17.1561 2 16.3562 2 15.4384Z"
                      stroke="#2B2B2B"
                      stroke-width="1.5"
                    />
                    <path
                      d="M22 15.4384V13.5616C22 12.6438 21.3754 11.8439 20.4851 11.6213L18.7455 11.1864C18.3668 11.0917 18 11.3781 18 11.7685V17.2315C18 17.6219 18.3668 17.9083 18.7455 17.8136L20.4851 17.3787C21.3754 17.1561 22 16.3562 22 15.4384Z"
                      stroke="#2B2B2B"
                      stroke-width="1.5"
                    />
                    <path
                      d="M20 18V18.5C20 19.6046 19.1046 20.5 18 20.5H14.5"
                      stroke="#2B2B2B"
                      stroke-width="1.5"
                    />
                    <path
                      d="M13.5 22H10.5C9.67157 22 9 21.3284 9 20.5C9 19.6716 9.67157 19 10.5 19H13.5C14.3284 19 15 19.6716 15 20.5C15 21.3284 14.3284 22 13.5 22Z"
                      stroke="#2B2B2B"
                      stroke-width="1.5"
                    />
                  </svg>
                </template>
              </ModalMenuItem>

              <ModalMenuItem
                :href="`tel:${_T('@Service phone Odesa')}`"
                class="modal-menu__item"
                @click="closeModal"
              >
                <template #title>
                  <span class="modal-menu__item-title">
                    {{ _T("@Service center Odesa") }}:
                  </span>
                  <span class="modal-menu__item-title">
                    {{ _T("@Service phone Odesa") }}
                  </span>
                </template>
                <template #image>
                  <img
                    src="/img/menu-service.svg"
                    :alt="_T('@Service center Odesa')"
                  />
                </template>
              </ModalMenuItem>
              <ModalMenuItem
                :href="`tel:${_T('@Service phone')}`"
                class="modal-menu__item"
                @click="closeModal"
              >
                <template #title>
                  <span class="modal-menu__item-title">
                    {{ _T("@Service center Kolomyia") }}:
                  </span>
                  <span class="modal-menu__item-title">
                    {{ _T("@Service phone Kolomyia") }}
                  </span>
                </template>
                <template #image>
                  <img
                    src="/img/menu-service.svg"
                    alt='{{ _T("@Service center Kolomyia") }}'
                  />
                </template>
              </ModalMenuItem>
            </section>

            <section class="modal-menu__section">
              <ModalMenuItem
                v-if="null !== userStore.currentUser"
                class="modal-menu__item"
                :route-name="'account_favorites'"
                @click="closeModal"
              >
                <template #title>
                  <span class="modal-menu__item-title"
                    >{{ `${_T("@Favorites")}` }}
                  </span>
                </template>
                <template #image>
                  <div class="cart-img">
                    <img
                      src="/img/account-favorite.svg"
                      alt="favorites button"
                    />
                    <div v-if="favoritesProducts > 0" class="cart-number">
                      <span class="cart-count">{{ favoritesProducts }}</span>
                    </div>
                  </div>
                </template>
              </ModalMenuItem>
              <ModalMenuItem
                v-else
                class="modal-menu__item"
                :is-button="true"
                @click="signIn"
              >
                <template #title>
                  <span class="modal-menu__item-title"
                    >{{ `${_T("@Favorites")}` }}
                  </span>
                </template>
                <template #image>
                  <div class="cart-img">
                    <img
                      src="/img/account-favorite.svg"
                      alt="favorites button"
                    />
                  </div>
                </template>
              </ModalMenuItem>

              <ModalMenuItem
                class="modal-menu__item"
                :route-name="'comparison'"
                @click="closeModal"
              >
                <template #title>
                  <span class="modal-menu__item-title"
                    >{{ `${_T("@Comparison")}` }}
                  </span>
                </template>
                <template #image>
                  <div class="cart-img">
                    <img
                      src="~~/assets/icons/comparison-mobile-menu.svg"
                      alt="comparison button"
                    />
                    <div
                      v-if="comparisonProducts.length > 0"
                      class="cart-number"
                    >
                      <span class="cart-count">{{
                        comparisonProducts.length
                      }}</span>
                    </div>
                  </div>
                </template>
              </ModalMenuItem>
            </section>

            <section class="modal-menu__section">
              <section class="modal-menu__item-dropdown">
                <div
                  class="modal-menu__menu-header"
                  :class="{ active: dropdownAboutCompany }"
                  @click="dropdownAboutCompany = !dropdownAboutCompany"
                >
                  <ModalMenuItem
                    class="modal-menu__item"
                    :is-button="true"
                    @click="closeModal('dropdown')"
                  >
                    <template #title>
                      <span class="modal-menu__item-title">
                        {{ _T("@About company") }}
                      </span>
                    </template>
                    <template #image>
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M10.6542 8.94022C11.0166 7.69274 12.2391 3.50542 12.7467 1.7668C12.8589 1.38275 12.5705 1 12.1704 1H11.2254C10.9589 1 10.7243 1.17587 10.6495 1.43175L7.4734 12.3037C7.30829 12.8613 7.67251 13.3118 8.27468 13.3118H12.6457C12.9013 13.3118 13.1288 13.1499 13.2125 12.9084L13.4957 12.0921C13.6309 11.7023 13.3414 11.2955 12.9288 11.2955H9.9598L10.6542 8.94022Z"
                          fill="#2B2B2B"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M16.2349 8.73438C16.8419 8.73438 17.2012 9.18492 17.041 9.74252L15.4433 14.8501C15.283 15.4077 14.6614 15.8583 14.0544 15.8583H11.1747C10.8104 17.1118 10.1112 19.4849 9.74208 20.7607H9.81781C9.99992 20.7607 10.0873 20.9842 9.9534 21.1077L8.30547 22.6275C8.10594 22.8115 7.78498 22.7476 7.67116 22.5012L6.99831 21.0446C6.93709 20.912 7.03389 20.7607 7.17988 20.7607H7.5422L9.27588 14.8546C9.43613 14.297 10.0577 13.8464 10.6648 13.8464H13.5445C13.763 13.0881 14.3312 11.509 14.5497 10.7551H11.5999C11.2016 10.7551 10.9138 10.3742 11.0227 9.99109L11.2547 9.17481C11.328 8.91683 11.5637 8.73884 11.8319 8.73884H16.2349V8.73438Z"
                          fill="#2B2B2B"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M7.82609 17.5611C7.90145 17.3045 7.79613 17.0311 7.57997 16.8735C5.95166 15.6869 4.9085 13.8521 4.9085 11.7971C4.9085 8.43061 7.70474 5.66195 11.2821 5.34608C11.5407 5.32324 11.7661 5.15136 11.8388 4.9021L12.1756 3.74694C12.2109 3.62569 12.12 3.5 11.9937 3.5C7.02582 3.5 3 7.198 3 11.7614C3 14.5454 4.50124 17.0099 6.80166 18.5033C7.12158 18.711 7.53975 18.5363 7.64721 18.1704L7.82609 17.5611Z"
                          fill="#2B2B2B"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M13.4118 3.59767C13.1272 3.55642 12.8615 3.73864 12.781 4.0148L12.6114 4.59746C12.5033 4.96848 12.7701 5.34256 13.152 5.40134C16.4895 5.91499 19.0287 8.58056 19.0287 11.7915C19.0287 15.3691 15.8722 18.2731 11.9726 18.2731C10.9879 18.2731 10.0516 18.087 9.20177 17.7522C8.85083 17.6139 8.44129 17.7887 8.33581 18.1508L8.18471 18.6696C8.10116 18.9565 8.24073 19.2631 8.51942 19.3709C9.59089 19.7853 10.7713 20.0172 12.0066 20.0172C16.9745 20.0172 21.0003 16.3192 21.0003 11.7558C20.9957 7.634 17.7089 4.2203 13.4118 3.59767Z"
                          fill="#2B2B2B"
                        />
                      </svg>
                    </template>
                  </ModalMenuItem>
                  <div class="modal-menu__item-arrow">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9 6L15 12L9 18"
                        stroke="#F36C21"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                </div>
                <div v-if="dropdownAboutCompany" class="modal-menu__menu-body">
                  <ul class="modal-menu__dropdown-list">
                    <li
                      v-for="(item, index) in aboutCompanyList"
                      :key="index"
                      class="modal-menu__dropdown-category"
                    >
                      <MyLink :name="item.routeName" @click="closeModal">
                        {{ _T(item.title) }}
                      </MyLink>
                    </li>
                  </ul>
                </div>
              </section>
              <ModalMenuItem
                :route-name="'installation-work'"
                class="modal-menu__item"
                @click="closeModal()"
              >
                <template #title>
                  <span class="modal-menu__item-title">
                    {{ _T("@Our objects") }}
                  </span>
                </template>
                <template #image>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M13.8691 3.08984H16.6391C18.3991 3.08984 19.8291 4.50984 19.8291 6.27984V13.6498"
                      stroke="#2B2B2B"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M8.92 3.08984H6.19C4.43 3.08984 3 4.50984 3 6.27984V19.1398C3 20.9098 4.43 22.3298 6.19 22.3298H11.92"
                      stroke="#2B2B2B"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M9.83016 1H12.9502C13.4602 1 13.8702 1.41 13.8702 1.92V5.19C13.8702 6.56 12.7602 7.67 11.3902 7.67C10.0202 7.67 8.91016 6.56 8.91016 5.19V1.92C8.91016 1.41 9.32016 1 9.83016 1Z"
                      stroke="#2B2B2B"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M9.91016 10.9609H16.2402"
                      stroke="#2B2B2B"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M6.9707 10.9609H8.2707"
                      stroke="#2B2B2B"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M9.91016 14.2891H16.2402"
                      stroke="#2B2B2B"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M6.9707 14.2891H8.2707"
                      stroke="#2B2B2B"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M20.8803 15.5898L17.2503 19.9398C16.5903 20.7298 16.2603 21.1298 15.8203 21.1498C15.3803 21.1698 15.0103 20.8098 14.2803 20.0798L12.8203 18.6198"
                      stroke="#2B2B2B"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </template>
              </ModalMenuItem>

              <ModalMenuItem
                :route-name="'contacts'"
                :hash="'#shops'"
                class="modal-menu__item"
                @click="closeModal()"
              >
                <template #title>
                  <span class="modal-menu__item-title">
                    {{ _T("@Our stores") }}
                  </span>
                </template>
                <template #image>
                  <img src="~~/assets/icons/stores-icon.svg" alt="stores" />
                </template>
              </ModalMenuItem>

              <ModalMenuItem
                :route-name="'warranty'"
                class="modal-menu__item"
                @click="closeModal()"
              >
                <template #title>
                  <span class="modal-menu__item-title">{{
                    _T("@Menu guarantee and service")
                  }}</span>
                </template>
                <template #image>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_9066_49953)">
                      <path
                        d="M22.266 6.67068C22.266 6.67068 18.9843 6.13655 18.1038 5.83133C16.8232 5.4498 15.5425 4.91566 14.5019 4.07631C14.4219 4 14.3419 4 14.2618 4C14.1818 4 14.0217 4 13.9416 4.07631C12.9011 4.91566 11.6204 5.4498 10.3397 5.83133C9.37924 6.13655 6.17755 6.67068 6.09751 6.67068C5.93743 6.67068 5.77734 6.82329 5.77734 7.05221C5.77734 13.2329 5.85739 16.0562 7.85844 18.9558C9.53932 21.4739 13.8616 22.9237 14.0217 23C14.1017 23 14.1017 23 14.1818 23H14.3419C14.5019 22.9237 18.8242 21.3976 20.5851 18.9558C22.5862 16.0562 22.6662 13.1566 22.6662 7.05221C22.5862 6.82329 22.4261 6.67068 22.266 6.67068Z"
                        fill="white"
                        stroke="#2B2B2B"
                        stroke-width="1.5"
                        stroke-miterlimit="10"
                      />
                      <path
                        d="M11.0547 13.5L13.1658 15.6111L17.388 11.3889"
                        stroke="black"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <g clip-path="url(#clip1_9066_49953)">
                        <path
                          d="M9.21455 10.2131L10.1596 8.83202L11.4823 8.99663L11.8219 7.38205L10.5443 7.00694L10.2254 5.32964L11.1847 4.27022L10.5618 3.31541L9.18732 3.77203L7.7997 2.83531L7.99707 1.51942L6.38249 1.17979L5.97178 2.46001L4.32895 2.779L3.30225 1.79638L2.32514 2.43386L2.77022 3.81587L1.83365 5.20324L0.519856 5.00466L0.180228 6.61924L1.46428 7.02905L1.77112 8.64384L0.796816 9.69948L1.43429 10.6766L2.79306 10.2112L4.17417 11.1562C4.1607 11.4727 4.00509 12.4819 4.00509 12.4819L5.61967 12.8215L6.00189 11.5385L7.6472 11.2298L8.71102 12.1976L9.67702 11.5674L9.21455 10.2131Z"
                          fill="white"
                          stroke="#2B2B2B"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M6 8.75C6.9665 8.75 7.75 7.9665 7.75 7C7.75 6.0335 6.9665 5.25 6 5.25C5.0335 5.25 4.25 6.0335 4.25 7C4.25 7.9665 5.0335 8.75 6 8.75Z"
                          stroke="#2B2B2B"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </g>
                    </g>
                    <defs>
                      <clipPath id="clip0_9066_49953">
                        <rect width="24" height="24" fill="white" />
                      </clipPath>
                      <clipPath id="clip1_9066_49953">
                        <rect
                          width="14"
                          height="14"
                          fill="white"
                          transform="translate(-1)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                </template>
              </ModalMenuItem>
            </section>

            <section class="modal-menu__section">
              <ModalMenuItem
                :route-name="'blog'"
                class="modal-menu__item"
                @click="closeModal()"
              >
                <template #title>
                  <span class="modal-menu__item-title">{{ _T("@Blog") }}</span>
                </template>
                <template #image>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M4 8L4 6L16 6V8"
                      stroke="#2B2B2B"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M10 6L10 18M10 18H12M10 18H8"
                      stroke="#2B2B2B"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M14 13.5L14 12L20 12V13.5"
                      stroke="#2B2B2B"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M17 12V18M17 18H15.5M17 18H18.5"
                      stroke="#2B2B2B"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </template>
              </ModalMenuItem>
            </section>

            <section class="modal-menu__section">
              <ModalMenuItem
                :route-name="'calculator'"
                class="modal-menu__item"
                @click="closeModal()"
              >
                <template #title>
                  <span class="modal-menu__item-title">{{
                    _T("@Capacity Calculator")
                  }}</span>
                </template>
                <template #image>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_4486_36138)">
                      <path
                        d="M1 21V3C1 1.89543 1.89543 1 3 1H21C22.1046 1 23 1.89543 23 3V21C23 22.1046 22.1046 23 21 23H3C1.89543 23 1 22.1046 1 21Z"
                        stroke="#2B2B2B"
                        stroke-width="1.5"
                      />
                      <path
                        d="M15 7L17 7H19"
                        stroke="#2B2B2B"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M15 15.5H17L19 15.5"
                        stroke="#2B2B2B"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M15 18.5H17H19"
                        stroke="#2B2B2B"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M5 7H7M9 7H7M7 7V5M7 7V9"
                        stroke="#2B2B2B"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M5.58609 18.4142L7.0003 17M8.41452 15.5858L7.0003 17M7.0003 17L5.58609 15.5858M7.0003 17L8.41452 18.4142"
                        stroke="#2B2B2B"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_4486_36138">
                        <rect width="24" height="24" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </template>
              </ModalMenuItem>

              <ModalMenuItem
                :route-name="'lookup-device'"
                class="modal-menu__item"
                @click="closeModal()"
              >
                <template #title>
                  <span class="modal-menu__item-title">{{
                    _T("@Serial Number Check")
                  }}</span>
                </template>
                <template #image>
                  <img
                    src="~~/assets/icons/serial-number.svg"
                    alt="serial number"
                  />
                </template>
              </ModalMenuItem>
            </section>
          </div>
        </main>
        <main class="modal-menu__catalog">
          <div class="modal-menu__catalog-wrapper">
            <ButtonBack @click="onCollapseCategories" />
            <ClientOnly>
              <ul class="modal-menu__catalog-menu">
                <MenuCatalogItem
                  v-for="category in categories"
                  :key="category.id"
                  :category="category"
                  @click="onExpandCategory(category)"
                />
              </ul>
            </ClientOnly>
          </div>
        </main>
        <footer class="modal-menu__footer">
          <p class="modal-menu__social-network-title">
            {{ _T("@We on social media") }}:
          </p>
          <div class="modal-menu__social-networks">
            <a
              v-for="social in socials"
              :href="social.link"
              class="modal-menu__social-item"
              target="_blank"
            >
              <img :src="social.iconOrange" :alt="social.alt" />
            </a>
          </div>
        </footer>
      </section>
    </div>
  </div>
</template>

<script setup>
import MenuCatalogItem from "~~/modules/header/sections/MenuCatalogItem.vue";
import ButtonLanguage from "~~/modules/header/components/UI/ButtonLanguage.vue";
import ModalMenuItem from "~~/modules/header/components/UI/ModalMenuItem.vue";
import ButtonBack from "~~/modules/header/components/UI/ButtonBack.vue";
import Logo from "~~/modules/header/components/UI/Logo.vue";
import { useHeaderStore } from "~~/store/headerStore";
import { useCategoriesStore } from "~~/store/categoriesStore";
import socials from "~/config/social.config";
import { addScopedEventListener } from "~~/utils/eventListener";
import { useCartStore } from "~~/store/cartStore";
import { useComparisonStore } from "~/store/comparisonStore";
import { useUserStore } from "~/store/userStore";
import {
  accountModal,
  cartModal,
  useModalStore,
} from "~/modules/shared/modals/store/modalStore";
import { useFavoriteProductStore } from "~/store/favoriteProductStore";
import {
  discontinuedCategoryCode,
  promotionCategoryCode,
} from "~/config/category.config";
import { isPromotionEnded } from "~/utils/valueMatched";

const categoriesStore = useCategoriesStore();
const {
  getCategoriesByParent: parentCategories,
  getChildren: children,
  getTopLevelCategories: topLevelCategories,
} = storeToRefs(categoriesStore);

const cartStore = useCartStore();
const { getProductsQuantity: cartQuantity } = storeToRefs(cartStore);

const comparisonStore = useComparisonStore();
const { getProducts: comparisonProducts } = storeToRefs(comparisonStore);

const favoriteProductStore = useFavoriteProductStore();
const { getProductsLength: favoritesProducts } =
  storeToRefs(favoriteProductStore);

const userStore = useUserStore();
const modalStore = useModalStore();

const dropdownAboutCompany = ref(false);
const mobileCatalog = ref(false);

const header = useHeaderStore();
const menuItems = header.getModalMenu;

const activeMenu = header.activeModal;
const activeCatalog = header.activeCatalog;

const categories = ref(null);

const aboutCompanyList = ref([
  { title: "@Delivery and payment", routeName: "delivery" },
  { title: "@Cooperation", routeName: "cooperation" },
]);

function closeModal(type) {
  if (type !== "dropdown") {
    header.activeModal(false);
    mobileCatalog.value = false;
  } else {
    header.activeModal(true);
  }
}

function showCart() {
  modalStore.toggleModal(cartModal);
  closeModal();
}

function openCatalog() {
  if (window.innerWidth > 1024) {
    activeMenu(false);
    activeCatalog(true);
    document.body.style.overflow = "hidden";
  } else {
    mobileCatalog.value = true;
  }
}

function defaultModal() {
  mobileCatalog.value = window.innerWidth <= 1024;
}

function onExpandCategory(category) {
  if (0 === children.value(category).length) {
    mobileCatalog.value = false;

    return (categories.value = topLevelCategories.value.filter(
      (category) => category.code !== discontinuedCategoryCode,
    ));
  }

  if (promotionCategoryCode !== category.code) {
    return (categories.value = children.value(category));
  }

  return (categories.value = children
    .value(category)
    .filter((child) => !isPromotionEnded(child))
    .sort((a, b) => b.promotion.period.endAt - a.promotion.period.endAt));
}

function onCollapseCategories() {
  if (null === categories.value[0].parent) {
    mobileCatalog.value = false;
  }

  categories.value = parentCategories
    .value(categories.value[0].parent)
    .filter((category) => category.code !== discontinuedCategoryCode);
}

function signIn() {
  modalStore.toggleModal(accountModal, { component: "signIn" });
  closeModal();
}

function signUp() {
  modalStore.toggleModal(accountModal, { component: "signUp" });
  closeModal();
}

onMounted(() => {
  categories.value = topLevelCategories.value.filter(
    (category) => category.code !== discontinuedCategoryCode,
  );
  addScopedEventListener(window, "resize", defaultModal);
});
</script>
